<template>
  <div class="rays-tuner-simplify">
    <div class="simplify-topbar">
      <div class="simplify-title">简化任务</div>
      <!--      简化任务 筛选搜索-->
      <div class="simplify-top-tools">
        <a-input
          placeholder="可输入名称关键词"
          v-model:value="search"
          size="large"
          class="simplify-search"
          @press-enter="searchList"
        >
          <template #prefix>
            <SearchOutlined />
          </template>
        </a-input>

        <a-select
          ref="select"
          v-model:value="select"
          style="width: 120px"
          class="simplify-select"
          @change="searchList"
        >
          <a-select-option value="-1">全部任务</a-select-option>
          <a-select-option value="6">已完成</a-select-option>
          <a-select-option value="2">进行中</a-select-option>
          <a-select-option value="1">队列中</a-select-option>
          <a-select-option value="4">手动停止</a-select-option>
          <a-select-option value="5">系统中止</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="simplify-body">
      <a-divider
        style="height: 1px; background-color: #d8d8d8"
        class="divider"
      />

      <!--      简化任务 空状态图标-->
      <div class="simplify-body-scroll">
        <div class="simplify-body-empty" v-show="paginationProps.total == 0">
          <div class="simplify-body-empty-logo">
            <inbox-outlined :style="{ fontSize: '60px', color: '#D8D8D8' }" />
          </div>
          <div class="simplify-body-empty-words">暂无任务</div>
        </div>

        <!--      简化任务 列表-->
        <div class="simplify-body-list" v-show="paginationProps.total > 0">
          <a-table
            id="simplify_task_body_scroll"
            :data-source="simplifyData"
            :columns="simplifyColumns"
            :showHeader="false"
            :pagination="paginationProps"
            :scroll="{ x: '1500px', y: 'calc(100vh - 217px)' }"
          >
            <template #pic="{ record }">
              <div class="simplify-list-pic">
                <a-image
                  :width="72"
                  :height="47"
                  :src="record.url"
                  :preview="false"
                  :fallback="imgSvg"
                />
              </div>
            </template>
            <template #name="{ record }">
              <div class="simplify-list-name">
                <div
                  class="simplify-list-id"
                  :class="{ middle: !record.comment }"
                >
                  {{ record.task_number }} | {{ record.shader_id }}
                </div>
                <svg-icon
                  icon-class="edit"
                  class="list_icon"
                  v-if="record.comment"
                />
                <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
                  <template #title>{{ record.comment }}</template>
                  <div class="simplify-list-remark" v-if="record.comment">
                    {{ record.comment }}
                  </div>
                </a-tooltip>
              </div>
            </template>
            <template #PAT="{ record }">
              <div class="simplify-list-info">
                <a-tooltip placement="topLeft" :destroyTooltipOnHide="true">
                  <template #title>
                    <span>{{ record.pat_name }}</span>
                  </template>
                  <div class="simplify-list-PAT">
                    {{ record.pat_name }}
                  </div>
                </a-tooltip>
                <div class="simplify-list-device">
                  Device:{{ record.device }} &nbsp;&nbsp; Frame:{{
                    record.frame
                  }}
                </div>
              </div>
            </template>
            <template #time="{ record }">
              <div class="simplify-list-time" v-if="record.status == '6'">
                提交时间：{{
                  record.add_time
                }}&nbsp;&nbsp;&nbsp;&nbsp;总用时：{{ record.total_time }}
              </div>
              <div class="simplify-list-rate" v-if="record.status != '6'">
                <!--              任务进度：{{ record.rate }}-->
                <a-progress
                  :percent="65"
                  strokeColor="#604bdc"
                  :show-info="false"
                  v-if="record.status == '2'"
                  status="active"
                />
                <a-progress
                  :percent="record.status * 10"
                  strokeColor="#555555"
                  :show-info="false"
                  v-if="record.status != '2'"
                />
              </div>
            </template>
            <template #status="{ record }">
              <div
                class="simplify-list-status"
                style="color: #aaaaaa"
                v-if="record.status == '8'"
              >
                删除中
              </div>
              <div
                class="simplify-list-status"
                style="color: #aaaaaa"
                v-if="record.status == '2'"
              >
                进行中...
              </div>
              <div
                class="simplify-list-status"
                style="color: #604bdc"
                v-if="record.status == '6'"
              >
                已完成
              </div>
              <div
                class="simplify-list-status"
                style="color: #3c3c3c"
                v-if="record.status == '3'"
              >
                <div
                  class="simplify-list-status-square"
                  style="
                    float: left;
                    background: rgba(255, 184, 0, 0.2);
                    border: 1px solid #ffb800;
                  "
                ></div>
                <div style="margin-left: 16px">停止中...</div>
              </div>
              <div
                class="simplify-list-status"
                style="color: #3c3c3c"
                v-if="record.status == '4'"
              >
                <div
                  class="simplify-list-status-square"
                  style="
                    float: left;
                    background: rgba(255, 184, 0, 0.2);
                    border: 1px solid #ffb800;
                  "
                ></div>
                <div style="margin-left: 16px">手动停止</div>
              </div>
              <div
                class="simplify-list-status"
                style="color: #3c3c3c"
                v-if="record.status == '5'"
              >
                <div
                  class="simplify-list-status-square"
                  style="
                    float: left;
                    background: rgba(255, 34, 34, 0.2);
                    border: 1px solid #ff2222;
                  "
                ></div>
                <div style="margin-left: 16px">
                  系统中止&nbsp;
                  <span class="status-reason">
                    <a-tooltip placement="top" :destroyTooltipOnHide="true">
                      <template #title>{{ record.intro }}</template>
                      <QuestionCircleFilled
                        :style="{ fontSize: '12px', color: '#231f20' }"
                      />
                    </a-tooltip>
                  </span>
                </div>
              </div>
              <div
                class="simplify-list-status"
                style="color: #aaaaaa"
                v-if="record.status == '1' || record.status == '0'"
              >
                <div
                  class="simplify-list-status-square"
                  style="
                    float: left;
                    background: rgba(170, 170, 170, 0.2);
                    border: 1px solid #aaaaaa;
                  "
                ></div>
                <div style="margin-left: 16px">队列中</div>
              </div>
            </template>
            <template #action="{ record }">
              <div class="simplify-list-action">
                <div class="action-operate-logo">
                  <!--                已完成-查看-->
                  <DiffOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="record.status == '6'"
                  />
                  <!--                进行/队列中-暂停-->
                  <PauseOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="
                      record.status == '2' ||
                        record.status == '1' ||
                        record.status == '0'
                    "
                  />
                  <!--                系统中止-重启-->
                  <ReloadOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="record.status == '5'"
                  />
                  <!--                手动停止-继续-右箭头-->
                  <CaretRightOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="record.status == '4'"
                  />
                  <!--                停止中不能操作-右箭头-->
                  <CaretRightOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                    v-if="record.status == '3'"
                  />
                </div>
                <div
                  class="action-operate-bg"
                  v-if="record.status == '6'"
                  @click="check(record)"
                ></div>
                <div
                  class="action-operate-bg"
                  v-if="
                    record.status == '2' ||
                      record.status == '1' ||
                      record.status == '0'
                  "
                  @click="operate(record, 0)"
                ></div>
                <div
                  class="action-operate-bg"
                  v-if="record.status == '5' || record.status == '4'"
                  @click="operate(record, 1)"
                ></div>
                <div
                  class="action-operate-bg"
                  v-if="record.status == '3' || record.status == '8'"
                  @click="Doing()"
                ></div>
                <!--              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
                <div class="action-delete-logo">
                  <DeleteOutlined
                    :style="{ fontSize: '16px', color: '#D8D8D8' }"
                  />
                </div>
                <a-popconfirm
                  title="确定要删除该任务吗？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="operate(record, 2)"
                  :destroyTooltipOnHide="true"
                >
                  <div
                    class="action-delete-bg"
                    v-show="record.status != '8' && record.status != '3'"
                  ></div>
                </a-popconfirm>
              </div>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <!--    <div class="simplify-footer">-->
    <!--&lt;!&ndash;      <button @click="test()">123</button>&ndash;&gt;-->
    <!--    </div>-->
  </div>
</template>

<script>
// import storage from '../models/storage';
import { message } from 'ant-design-vue';
import {
  SearchOutlined,
  InboxOutlined,
  DeleteOutlined,
  DiffOutlined,
  PauseOutlined,
  ReloadOutlined,
  CaretRightOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons-vue';
import store from '@/vuex/store';
import { toShaderSimplify, setSimplifyHomeInfo } from '@/models/tunerStorage';
import PerfectScrollbar from 'perfect-scrollbar';
import { nextTick } from 'vue';
import moment from 'moment';
import api from '@/api/task';
import { getBaseUrl } from '@/api/service';
import { imgSvg72_47 as imgSvg } from '@/utils/img';
const engineurl = getBaseUrl('engine');

let simplifyTaskDOM = null;
let simplifyTaskPs = null;

export default {
  name: 'SimplifyTask',
  data() {
    return {
      timer: null,
      search: '',
      select: '-1',
      imgSvg: imgSvg,
      paginationProps: {
        size: 'small',
        showQuickJumper: true,
        position: 'bottom',
        total: 0,
        pageSize: 9,
        current: 1,
        onChange: (current) => {
          // console.log('paginationProps', current, this.paginationProps);
          this.paginationProps.current = current;
          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.getList();
        },
      },
      simplifyData: [],
      simplifyColumns: [
        {
          title: 'Pic',
          slots: { customRender: 'pic' },
          width: '6%',
          key: 'pic',
          fixed: 'left',
        },
        {
          title: 'Name',
          slots: { customRender: 'name' },
          width: '18%',
          key: 'name',
          fixed: 'left',
        },
        {
          title: 'PAT',
          dataIndex: 'pat_name',
          key: 'pat_name',
          slots: { customRender: 'PAT' },
          width: '20%',
        },
        {
          title: 'Time',
          slots: { customRender: 'time' },
          key: 'time',
          width: '26%',
        },
        {
          title: 'Status',
          slots: { customRender: 'status' },
          dataIndex: 'status',
          key: 'status',
          width: '10%',
        },
        {
          title: 'Action',
          slots: { customRender: 'action' },
          key: 'action',
          width: '10%',
          fixed: 'right',
        },
      ],
    };
  },
  components: {
    SearchOutlined,
    InboxOutlined,
    DeleteOutlined,
    DiffOutlined,
    PauseOutlined,
    ReloadOutlined,
    CaretRightOutlined,
    QuestionCircleFilled,
  },
  methods: {
    // 处理时间
    formateDate(start, end) {
      if (
        !start ||
        !end ||
        start == '0000-00-00 00:00:00' ||
        end == '0000-00-00 00:00:00'
      ) {
        return "00'00'00";
      }
      // 对比两个时间相差多少秒
      const second = moment(start).diff(moment(end), 'seconds');
      // 把数值型的毫秒值转化为时分秒格式
      const res = moment.utc(Math.abs(second) * 1000).format(`HH'mm'ss`);
      // console.log('formateDate',start,end,second, second/60,second/60/60, res);
      return res;
    },
    sortPat(tasks) {
      const _pats = [];
      for (let i in tasks) {
        let _pi = -1;
        for (let pindex = 0; pindex < _pats.length; pindex++) {
          if (tasks[i].pat_name == _pats[pindex].patname) {
            _pi = pindex;
            break;
          }
        }
        if (_pi == -1) {
          _pats.push({
            patname: tasks[i].pat_name,
            shaderids: [parseInt(tasks[i].shader_id)],
          });
        } else if (
          !_pats[_pi].shaderids.includes(parseInt(tasks[i].shader_id))
        ) {
          _pats[_pi].shaderids.push(parseInt(tasks[i].shader_id));
        }
      }
      return _pats;
    },
    async getList(count = 0) {
      // console.log('getList',count);
      const user_name = store.state.user.userinfo;
      // console.log('user_name:',user_name);
      const _param = {
        user_id: user_name.uid,
        type_id: 1,
      };
      if (this.search) {
        _param.keyword = this.search;
      }
      if (this.select != '-1') {
        _param.status = this.select;
        if (_param.status == '1') {
          _param.status += ',0';
        } else if (_param.status == '4') {
          _param.status += ',3';
        }
      }
      const url = `/index.php/Task/gets?page=${this.paginationProps.current}&pageSize=${this.paginationProps.pageSize}`;
      const res = await api.taskGets(url, _param);
      if (!res) return;
      if (res.code != 0) {
        message.error(' 获取简化任务列表失败！');
        return;
      }
      this.paginationProps.total = res.total || 0;
      const chargeData = res.data;
      const comParams = {
        user: user_name.email,
        pats: this.sortPat(chargeData),
      };
      const comRes = await api.getShaderCommentList(comParams);
      if (!comRes) return;
      if (comRes.code != 0) {
        message.error('获取简化任务列表失败');
        return;
      }
      const _shaders = JSON.parse(comRes.result_list || '[]');
      const _list = [];
      let _hasing = false;
      for (let n in chargeData) {
        // 处理params中的缩略图数据
        chargeData[n].key = n;
        const _params = JSON.parse(chargeData[n].params || '{}');
        if (_params.highlight) {
          chargeData[n].url =
            engineurl +
            '/api/sopt/simplifier/getHighlightByFilename?filename=' +
            _params.highlight +
            '&pat=' +
            chargeData[n].pat_name +
            '&user=' +
            user_name.email +
            '&pid=' +
            chargeData[n].shader_id;
        } else {
          chargeData[n].url = '';
        }
        chargeData[n].total_time = this.formateDate(
          chargeData[n].start_time,
          chargeData[n].end_time
        );
        chargeData[n].device = _params.device_type;
        chargeData[n].frame = _params.begin_frame + '-' + _params.end_frame;
        // if (n == 5) {
        //   chargeData[n].status = '3';
        // }
        if (chargeData[n].status == '3' || chargeData[n].status == '8') {
          _hasing = true;
        }
        for (let i = 0; i < _shaders.length; i++) {
          if (
            _shaders[i].pat == chargeData[n].pat_name &&
            _shaders[i].shader_id == parseInt(chargeData[n].shader_id)
          ) {
            chargeData[n].comment = _shaders[i].comment;
            break;
          }
        }
        // console.log('_shaders', _shaders, chargeData[n].pat_name, parseInt(chargeData[n].shader_id));
        _list.push(chargeData[n]);
      }
      this.simplifyData = _list;
      // console.log('simplifyData:',this.simplifyData);

      if (simplifyTaskDOM && simplifyTaskPs) {
        nextTick(() => {
          simplifyTaskPs.update();
        });
      }
      if (!_hasing) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
      } else if (count < 10) {
        this.timer = setTimeout(() => {
          this.getList(++count);
        }, 1000);
      }
    },
    async operate(item, type = 0) {
      this.getList();
      const opts = ['stopAsyncTask', 'continueAsyncTask', 'deleteAsyncTask'];
      const opttips = ['停止', '继续', '删除'];
      const proID = item.task_number;
      // console.log('pause pro id:',proID);
      const user_name = store.state.user.userinfo;
      const email = user_name.email;
      const params = {
        user: email,
        task_id: proID,
      };
      const url = `/api/sopt/simplifier/${opts[type]}`;
      const res = await api.handleTask(url, params, { noMessage: true });
      if (!res || res.code != 0) {
        message.error(opttips[type] + '简化任务失败！');
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.getList();
      message.success(opttips[type] + '简化任务成功！');
    },
    searchList() {
      this.paginationProps.current = 1;
      this.getList();
    },

    check(value) {
      const getproid = JSON.parse(JSON.stringify(value));
      let proID = getproid.task_number;
      let shader = getproid.shader_id;
      let pat = getproid.pat_name;
      // console.log('查看任务详情.....check pro id:',proID);

      toShaderSimplify(pat, shader, proID);
    },

    Doing() {
      message.warning('操作中，请稍后');
    },
  },
  computed: {
    simplifyHome() {
      return store.state.shaderTool.simplifyHome || {};
    },
  },
  mounted() {
    this.getList();
    // console.log('current:',this.paginationProps);

    simplifyTaskDOM = document.querySelector(
      '#simplify_task_body_scroll .ant-table-body'
    );
    simplifyTaskPs = new PerfectScrollbar(simplifyTaskDOM);
    if (this.simplifyHome.menuKey[0] != '3') {
      const _simp = JSON.parse(JSON.stringify(this.simplifyHome));
      _simp.menuKey = ['3'];
      setSimplifyHomeInfo(_simp);
    }
  },
  beforeUnmount() {
    if (simplifyTaskPs) {
      simplifyTaskPs.destroy();
      simplifyTaskPs = null;
    }
    simplifyTaskDOM = null;
  },
};
</script>

<style scoped>
.rays-tuner-simplify {
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: #fff;
  overflow: auto;
}
.simplify-topbar {
  /*border-style: solid;*/
  /*border-color: #604bdc;*/
  width: 100%;
  height: 40px;
}
.simplify-title {
  width: 120px;
  height: 40px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  text-align: LEFT;
  color: #222222;
  line-height: 32px;
  float: left;
}
.simplify-top-tools {
  width: 454px;
  height: 40px;
  float: right;
  position: relative;
  /* border: 1px solid black; */
}
.simplify-search {
  width: 320px;
  height: 32px;
  margin-right: 14px;
}
.simplify-select {
  position: absolute;
}
.simplify-body {
  width: 100%;
  background: white;
  height: calc(100% - 64px);
  position: relative;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  /*overflow:auto;*/
}
.simplify-body-scroll {
  width: 100%;
  background: white;
  height: 100%;
  position: relative;
  /*border: 3px dashed rgba(0,0,0,.2);*/
  overflow: auto;
  /*height: auto;*/
}
.simplify-body-empty {
  position: absolute;
  top: calc(50% - 42px);
  left: calc(50% - 31px);
}
.simplify-body-empty-logo {
  height: 62px;
  width: 62px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.simplify-body-empty-words {
  width: 62px;
  height: 22px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Semibold;
  text-align: center;
  color: #d8d8d8;
  line-height: 32px;
}
.divider {
  margin-bottom: 0;
}
.simplify-list-pic {
  width: 72px;
  height: 47px;
  overflow: hidden;
  /*padding-bottom: 4px;*/
  /*border: 1px dashed rgba(0,0,0,.2);*/
}
.simplify-list-name {
  width: 240px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  height: 47px;
}
.simplify-list-id {
  /*border: 1px dashed rgba(0,0,0,.2);*/
  width: 240px;
  height: 24px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: LEFT;
  color: #3c3c3c;
  line-height: 24px;
}
.simplify-list-id.middle {
  padding-top: 12px;
}
.simplify-body-list .list_icon {
  width: 16px;
  height: 16px;
  vertical-align: 3px;
}
.simplify-list-remark {
  /*border: 1px dashed rgba(0,0,0,.2);*/
  width: 224px;
  height: 20px;
  display: inline-block;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #666666;
  line-height: 20px;
  vertical-align: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.simplify-list-info {
  height: 47px;
}
.simplify-list-PAT {
  /*border: 1px dashed rgba(0,0,0,.2);*/
  width: 250px;
  height: 22px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #8c8c8c;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.simplify-list-device {
  /*border: 1px dashed rgba(0,0,0,.2);*/
  width: 290px;
  height: 20px;
  display: inline-block;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #666666;
  line-height: 20px;
  vertical-align: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.simplify-list-time {
  width: 375px;
  height: 22px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #3c3c3c;
  line-height: 22px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
}
.simplify-list-rate {
  width: 330px;
}
.simplify-list-status {
  width: 100px;
  position: relative;
  /*border: 1px dashed rgba(0,0,0,.2);*/
}
.simplify-list-status-square {
  position: absolute;
  top: 17px;
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
.status-reason {
  min-height: 30px;
}
.simplify-list-action {
  width: 150px;
  height: 47px;
  padding-top: 4px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  position: relative;
}
.action-operate-logo {
  position: absolute;
  left: 8px;
  width: 16px;
  height: 16px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  z-index: 2;
  pointer-events: none;
}
.action-operate-bg {
  position: absolute;
  top: 8px;
  width: 32px;
  height: 32px;
}
.action-operate-bg:hover {
  background: #604bdc;
  border-radius: 4px;
}
.action-delete-logo {
  position: absolute;
  left: 60px;
  width: 16px;
  height: 16px;
  /*border: 1px dashed rgba(0,0,0,.2);*/
  z-index: 2;
  pointer-events: none;
}
.action-delete-bg {
  position: absolute;
  top: 8px;
  left: 52px;
  width: 32px;
  height: 32px;
}
.action-delete-bg:hover {
  background: #ff2222;
  border-radius: 4px;
}
#simplify_task_body_scroll {
  height: 100%;
}
:deep(.ant-table) {
  line-height: 3;
  min-height: calc(100vh - 217px);
  /*border: 1px dashed rgba(0,0,0,.2);*/
}
.simplify-footer {
  width: 100%;
  height: 30px;
  padding-top: 3px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.simplify-footer-tool {
  padding-bottom: 20px;
  padding-right: 30px;
  float: right;
}
</style>
<style>
#simplify_task_body_scroll .ps__rail-x,
#simplify_task_body_scroll .ps__rail-y {
  z-index: 2;
}
#simplify_task_body_scroll img {
  background-color: #222;
  border-radius: 4px;
}
</style>
