import { webServiceApi, engineServiceApi } from '../service';
export default {
  // 获取任务列表接口
  taskGets(url, params) {
    return webServiceApi.post(url, params);
  },

  // 停止任务
  stopAsyncTask(params) {
    return engineServiceApi.post('/api/sopt/simplifier/stopAsyncTask', params);
  },

  // 继续/重启任务
  continueAsyncTask(params, config) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/continueAsyncTask',
      params,
      config
    );
  },

  // 删除任务
  deleteAsyncTask(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/deleteAsyncTask',
      params
    );
  },

  // 删除购物车
  cartDel(params) {
    return webServiceApi.post('/index.php/Cart/del', params);
  },

  // 获取该简化shader唯一标识md5，用以添加到购物车中
  getOriginalShaderMD5(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getOriginalShaderMD5',
      params
    );
  },

  // 添加购物车接口
  cardAdd(params) {
    return webServiceApi.post('/index.php/Cart/add', params);
  },

  // 操作任务
  handleTask(url, params, config) {
    return engineServiceApi.post(url, params, config);
  },

  // 获取指定若干Shader备注
  getShaderCommentList(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getShaderCommentList',
      params
    );
  },

  // get请求示例
  getExample(params) {
    return webServiceApi.get('/xxxx/xxx', { params });
  },
};
