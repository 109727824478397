export const imgSvg72_47 =
  'data:image/svg+xml;utf8,<svg width="72" height="47" viewBox="-13 -13 50 50" fill="gray" ' +
  'xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" ' +
  'd="M19 17.834C19 18.478 18.552 19 18 19H6L13.566 12.183C13.812 11.938 14.258 11.939 14.499 ' +
  '12.182L19 16.66V17.834ZM8 7C8.828 7 9.5 7.672 9.5 8.5C9.5 9.328 8.828 10 8 10C7.172 10 6.5 9.328 ' +
  '6.5 8.5C6.5 7.672 7.172 7 8 7ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 ' +
  '21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"/></svg>';

export const imgSvg128_128 =
  'data:image/svg+xml;utf8,<svg width="128" height="128" viewBox="-13 -13 50 50" fill="gray" ' +
  'xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" ' +
  'd="M19 17.834C19 18.478 18.552 19 18 19H6L13.566 12.183C13.812 11.938 14.258 11.939 14.499 ' +
  '12.182L19 16.66V17.834ZM8 7C8.828 7 9.5 7.672 9.5 8.5C9.5 9.328 8.828 10 8 10C7.172 10 6.5 9.328 ' +
  '6.5 8.5C6.5 7.672 7.172 7 8 7ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 ' +
  '21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"/></svg>';

export const imgSvg96_64 =
  'data:image/svg+xml;utf8,<svg width="96" height="64" viewBox="-13 -13 50 50" fill="gray" ' +
  'xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" ' +
  'd="M19 17.834C19 18.478 18.552 19 18 19H6L13.566 12.183C13.812 11.938 14.258 11.939 14.499 ' +
  '12.182L19 16.66V17.834ZM8 7C8.828 7 9.5 7.672 9.5 8.5C9.5 9.328 8.828 10 8 10C7.172 10 6.5 9.328 ' +
  '6.5 8.5C6.5 7.672 7.172 7 8 7ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 ' +
  '21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"/></svg>';
